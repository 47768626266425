@charset "utf-8";

// Dimensions
$max-content-width: 800px;

@import
  "variables",
  "themes",
  "layout",
  "base",
  "distill"
;

.logo img {
  border-radius: 4px;
  height: 5rem;
  margin-left: 2rem;
}
